<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card id="questionBank">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}
             <div class="btn_in_header">
              <b-btn class="float-right" variant="primary" @click="gotoDashBoard()" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
              App DashBoard
              </b-btn>
              <b-button variant="primary mr-3" @click="quizBankAdd">{{cvbtnAddNew}}</b-button>
             </div>
            </h4>
          </template>
          <template v-slot:body>
            <div>
              <h5> Name: <span class="pointer" @click="detail_page()">{{appDetails.app_name}}</span></h5>
              <h5><small class="pointer" @click="detail_page()">Id: <span>{{appDetails.app_id}}</span></small></h5>
            </div>
            <h6><small>App Type: <span>{{appDetails.app_type}}</span></small></h6>
            <h6><small>Category: <span>{{appDetails.app_category}}</span></small></h6><br/>
            <b-badge title="Show active questions only" :style="setTagStyles('active')" class="tags mr-2 mb-2 font-weight-normal" variant="none" @click="filterQuestions(0)">
              <span>Questions Active: <span>{{total_questions || 0}}</span></span>
            </b-badge>
            <b-badge title="Show deleted questions only" :style="setTagStyles('deleted')" class="tags mr-2 mb-2 font-weight-normal" variant="none" @click="filterQuestions(1)">
              <span>Questions Deleted: <span>{{total_questions_deleted || 0}}</span></span>
            </b-badge>
            <b-button variant="mr-1 mb-1" @click="deleteQuizQuestions(appDetails)" title="Delete All Questions" class="delete_button">
              <i class="ri-delete-bin-line font-size-20" aria-hidden="true"></i>
            </b-button>
            <b-row>
              <b-col class="col-12 col-sm-8 col-md-6 col-lg-7 mb-3">
                <!-- <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
                -->

                <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText"
                />
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button"/>
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"/>
              </b-col>
              <b-col class="w-100 col-12 col-sm-12 col-md-12 col-lg-12 mb-3" v-if="quizBankObjList && quizBankObjList.length">
                <!-- Backend csv download -->
                  <b-btn v-if="quizBankObjList && quizBankObjList.length > 0" variant="primary" class="pull-right" :csv-title="csvTitle" @click="backendCsvDownload"> CSV Backend</b-btn>
                <!-- Backend csv download -->
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="quizBankObjList && quizBankObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="quizBankObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(qbk_id)="data">
                    <span><small>Id: {{data.item.qbk_id}}</small></span><br>
                    <b-button class="actionIcon mr-1 mt-1" size="sm" @click="quizBankEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill font-size-20 m-0"></i></b-button>
                    <b-button class="actionIcon mt-1" @click="showQuestionBankDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0 font-size-20" title="Delete"></i></b-button>
                    <b-button class="actionIcon mt-1" @click="quizQuestionview('MODAL', data.item)" ><i class="fa-solid fa-eye font-size-20"></i></b-button>
                    <b-button class="actionIcon mt-1" @click="solveQuizBankViaGPT(data.item)" title="Solve Question via AI" ><i class="fa fa-quora font-size-20"></i></b-button>
                  </template>

                  <template v-slot:cell(question)="data">
                    <span @click="imgModal(data.item.question)" class="magnifying_glass">
                      <span v-html="data.item.question"></span>
                    </span>
                  </template>

                  <template v-slot:cell(deleted)="data">
                    <b-badge v-if="data.item.deleted == 1" style="background-color: orangered !important;">Deleted</b-badge>
                    <b-badge v-else style="background-color: green !important;">Active</b-badge>
                  </template>

                  <template v-slot:cell(qbk_category)="data">
                    Q.Category: <span class="primary-color">{{ data.item.qbk_category }}</span><br>
                    Skill: <span class="primary-color">{{ data.item.skill }}</span><br>
                    Difficulty Level: <span class="primary-color">{{ data.item.difficulty }}</span>
                  </template>

                  <template v-slot:cell(answer_choices)="data">
                    <span>{{ replace_tags(data.item.answer_choices) }}</span>
                  </template>

                  <template v-slot:cell(answer_explanation)="data">
                    <span v-if="data.item.answer_explanation">
                      <span class="success-color"><b>Explanation Generated</b></span>
                      <b-button class="actionIcon mt-1" @click="quizQuestionview('MODAL', data.item)" ><i class="fa-solid fa-eye font-size-20"></i></b-button>
                    </span>
                    <span class="warning-color" v-else><b>Not Available</b></span>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="7">
                <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentBatchNo"
                  :total-rows="totalRows"
                  :per-page="dataPerPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelQuestionBankAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
      >
      <QuizBankAdd :propAppId="propAppId" :propOpenedInModal="true" @emitCloseQuestionBankAddModal="closeQuestionBankAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="quizBankAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeQuestionBankAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelQuestionBankEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <QuizBankEdit :propOpenedInModal="true" :propQuestionBankObj="quizBankEditObj" @emitCloseQuestionBankEditModal="closeQuestionBankEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="quizBankEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeQuestionBankEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelQuestionQuizView"
      scrollable
      :title="cvViewModalHeader"
      size="xl"
    >
      <QuestionQuizView :propOpenedInModal="true" :propQuestionBankObj="QuestionQuizViewObj" @emitCloseQuestionQuizViewModal="closeQuestionQuizViewModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="quizQuestionview('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeQuestionQuizViewModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelQuestionBankDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelQuestionBankDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="quizBankDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelImageView"
      scrollable
      size="xl"
      >
      <image-view :propImageUrl="propImageUrl"/>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { QuizBanks } from "../../../FackApi/api/quizBank.js"
import QuizBankAdd from "./QuizBankAdd.vue"
import QuizBankEdit from "./QuizBankEdit.vue"
import QuestionQuizView from "../../Gide/QuizBank/QuestionQuizView.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"
import { Apps } from "../../../FackApi/api/apps.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission"
import ImageView from "./ImageView.vue"

export default {
  name: "QuestionBankList",
  components: {
    QuizBankAdd,
    QuizBankEdit,
    CsvDownload,
    CsvUpload,
    QuestionQuizView,
    ImageView
  },
  data () {
    return {
      apiName: "quiz_bank_list",
      cvCardTitle: "Question Bank",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Question",
      cvAddModalHeader: "Add Question",
      cvViewModalHeader: "View Question",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Question Bank List",
      showModelQuestionBankAdd: false,
      showModelQuestionBankEdit: false,
      showModelQuestionQuizView: false,
      showModelQuestionBankDelete: false,
      showModelImageView: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Status", key: "deleted", class: "text-left align-text-top", sortable: true },
        { label: "Question Id ", key: "qbk_id", class: "text-left align-text-top w-300px", sortable: false },
        { label: "Category", key: "qbk_category", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Question", key: "question", class: "text-left align-text-top w-300px", sortable: true },
        // { label: "Answer Choices", key: "answer_choices", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Correct Answers", key: "correct_answers", class: "text-left align-text-top", sortable: true },
        { label: "Answer Explanation", key: "answer_explanation", class: "text-left align-text-top", sortable: true }
      ],
      quizBankObjList: [],
      quizBankEditObj: null,
      QuestionQuizViewObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      cvModuleFile: "app_quiz_bank",
      cvModulePrefix: "qbk",
      quizResData: [],
      answerChoiceArr: [],
      appDetails: {},
      total_questions: 0,
      total_questions_deleted: 0,
      propImageUrl: null,
      propAppId: null,
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true,
      questionState: null,
      csvTitle: "QuestionBankList",
      whereFilter: {
        search_param: ""
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    if (this.$route.params.app_id) {
      this.app_id = this.$route.params.app_id
      this.appView()
    }
    this.quizBankList()
    socialvue.index()
  },
  methods: {
    /**
     * gotoDashBoard
     */
    gotoDashBoard () {
      this.$router.push(`../quiz_list`)
    },
    /**
     * deleteQuizQuestions
     */
    async deleteQuizQuestions (appDetails) {
      try {
        const deleteObj = {}
        if (appDetails.app_type == "test_prep" || appDetails.app_type == "competition" || appDetails.app_type == "counselling") {
          deleteObj.app_id = appDetails.app_id
        }
        else {
          deleteObj.app_category = appDetails.app_category
        }
        const bulDeleteResp = await QuizBanks.quizBankBulkDelete(this, deleteObj)
        ApiResponse.responseMessageDisplay(this, bulDeleteResp)
      }
      catch (err) {
        console.error("Exception in deleteQuizQuestions and err: ", err)
      }
    },
    /**
     * gotoDashBoard
     */
    detail_page () {
      this.$router.push(`../quiz_detail/${this.app_id}`)
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      await this.quizBankList()
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    replace_tags (choices) {
      for (let k in choices) {
        choices[k] = choices[k].replace(/<p>/g, "").replace(/<\/p>/g, "")
      }
      return choices
    },
    /**
     * appView
     */
    async appView () {
      try {
        const appViewResp = await Apps.appView(this, this.app_id)
        if (!appViewResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, appViewResp)
        }
        this.appDetails = appViewResp.resp_data.data
      }
      catch (err) {
        console.error("Exception in appView()")
      }
    },
    /**
     * quizBankList
     */
    async quizBankList (loadViaLoadMore = false, downloadCsv = false) {
      try {
        const payload = {
          app_id: this.$route.params.app_id,
          search_param: this.whereFilter.search_param,
          admin_console: 1
        }

        if (this.questionState != null) {
          // this.questionState = 0 => active
          // this.questionState = 1 => deleted
          payload.question_state = this.questionState
        }

        // setting data for pagination
        payload.currentBatchNo = this.currentBatchNo
        payload.dataPerPage = this.dataPerPage
        payload.downloadCsv = downloadCsv
        if (this.$route.path.includes("quizBank_list")) {
          payload.is_management_panel = true
        }

        let quizBankListResp = await QuizBanks.quizBankList(this, payload)
        if (quizBankListResp.resp_status) {
          if (downloadCsv) {
            window.open(quizBankListResp.resp_csv_file_url)
            return
          }

          if (loadViaLoadMore) {
            this.quizBankObjList = [...this.quizBankObjList, ...quizBankListResp.resp_data.data]
            this.quizResData = this.quizBankObjList
          }
          else {
            this.quizBankObjList = quizBankListResp.resp_data.data
            this.quizResData = this.quizBankObjList
          }

          this.totalRows = this.quizBankObjList.length

          this.total_questions = quizBankListResp.resp_data.question_count

          // If active state then we only get the active questions in resp, so use the previous deleted state else reset to 0
          this.total_questions_deleted = this.questionState == 0 ? this.total_questions_deleted : 0

          for (let i in this.quizBankObjList) {
            if (this.quizBankObjList[i].deleted != 0) {
              this.total_questions_deleted += 1
            }
          }
        }
        else {
          this.showLoadMoreBtn = false
          this.toastMsg = quizBankListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          this.currentBatchNo = this.currentBatchNo - 1
        }
      }
      catch (err) {
        console.log("Exception occurred at quizBankList() and Exception:", err.message)
      }
    },
    /**
     * imgModal
     */
    imgModal (quesObj) {
      try {
        this.propImageUrl = quesObj
        this.showModelImageView = true
      }
      catch (err) {
        console.error("Exception occurred at ImageView() and Exception:", err.message)
      }
    },
    /**
     * setTagStyles
     */
    setTagStyles (questionType) {
      let styles = "cursor:pointer;font-size:1rem;"
      if (questionType == "active" && this.questionState == 0) {
        styles += "background-color:#109618;color:#fff;"
      }
      else if (questionType == "active") {
        styles += "border:1px solid #109618;color:#109618;"
      }
      if (questionType == "deleted" && this.questionState == 1) {
        styles += "background-color:#e5252c;color:#fff;"
      }
      else if (questionType == "deleted") {
        styles += "border:1px solid #e5252c;color:#e5252c;"
      }
      return styles
    },
    /**
     * filterQuestions
     */
    async filterQuestions (questionState) {
      try {
        this.questionState = questionState
        this.currentBatchNo = 1
        this.showLoadMoreBtn = true
        this.quizBankList()
      }
      catch (err) {
        console.error("Exception in filterQuestions and err: ", err)
      }
    },
    /**
     * quizBankAdd
     */
    quizBankAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push(`/quizBank_add/${this.app_id}`)
        }
        else {
          this.propAppId = this.$route.params.app_id
          this.showModelQuestionBankAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at quizBankAdd() and Exception:", err.message)
      }
    },
    /**
     * quizBankEdit
     */
    quizBankEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/quizBank_edit/" + this.quizBankEditObj.qbk_id)
        }
        else {
          this.quizBankEditObj = item
          this.showModelQuestionBankEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at quizBankEdit() and Exception:", err.message)
      }
    },
    /**
    * quizQuestionview
    */
    quizQuestionview (type, item) {
      try {
        if (type === "FULLPAGE") {
          window.open("/quiz_question_view/" + this.QuestionQuizViewObj.qbk_id)
        }
        else {
          this.QuestionQuizViewObj = item
          this.showModelQuestionQuizView = true
        }
      }
      catch (err) {
        console.error("Exception occurred at quizQuestionview() and Exception:", err.message)
      }
    },
    /**
     * showQuestionBankDeleteDialog
     */
    showQuestionBankDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelQuestionBankDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showQuestionBankDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * quizBankDelete
     */
    async quizBankDelete () {
      try {
        let quizBankDelResp = await QuizBanks.quizBankDelete(this, this.delObj.qbk_id)
        await ApiResponse.responseMessageDisplay(this, quizBankDelResp)

        if (quizBankDelResp && !quizBankDelResp) {
          this.showModelQuestionBankDelete = false
          return false
        }

        let index = this.quizBankObjList.indexOf(this.delObj)
        this.quizBankObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.total_questions--
        this.showModelQuestionBankDelete = false
      }
      catch (err) {
        console.error("Exception occurred at quizBankDelete() and Exception:", err.message)
      }
    },
    /**
     * closeQuestionBankAddModal
     */
    closeQuestionBankAddModal (quizBankAddData) {
      try {
        if (quizBankAddData) {
          if (this.quizBankObjList && this.quizBankObjList.length >= 1) {
            let qbkObjLength = this.quizBankObjList.length
            let lastId = this.quizBankObjList[qbkObjLength - 1]["id"]
            quizBankAddData.id = lastId + 1
          }
          else {
            this.quizBankObjList = []
            quizBankAddData.id = 11111
          }

          quizBankAddData.created_on = moment()
          this.quizBankObjList.unshift(quizBankAddData)
          this.totalRows = this.totalRows + 1
          this.total_questions++
        }

        this.showModelQuestionBankAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closequizBankAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeQuestionBankEditModal
     */
    closeQuestionBankEditModal () {
      try {
        this.showModelQuestionBankEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeQuestionBankEditModal() and Exception:", err.message)
      }
    },
    /**
     * closeQuestionQuizViewModal
     */
    closeQuestionQuizViewModal () {
      this.showModelQuestionQuizView = false
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.quizBankList(true)
    },
    /**
     * solveQuizBankViaGPT
     */
    async solveQuizBankViaGPT (questionData) {
      try {
        const solveObj = {
          qbk_id: questionData.qbk_id,
          question: questionData.question,
          answer_choices: JSON.stringify(questionData.answer_choices),
          image: questionData.image
        }

        let quizSolveResp = await QuizBanks.quizBankSolve(this, solveObj)
        if (!quizSolveResp.resp_status) {
          this.toastMsg = quizSolveResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.toastMsg = quizSolveResp.resp_msg
        this.toastVariant = "success"
        this.showToast = true
      }
      catch (err) {
        console.error("Exception in solveQuizBankViaGPT and err: ", err)
      }
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.quizBankList(false, true)
    }
  }
}
</script>
<style scoped>
.delete_button{
  color: #e5252c;
  border: 1px solid;
  padding: 0px 10px;
  padding-right: 7px;
}
.card-title{
  display: block !important;
}
.btn_in_header{
  float: right;
}
</style>
