/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3" v-if="!app_id">
                  <label for="validationcategory">
                    {{ cvCategoryLabel }}</label><br/>
                  <select v-model="vmQuestionBankFormData.app_category" class="form-control">
                     <option disabled value="">Please select one</option>
                     <option v-for="item in vmqbkExamCategoryList" :key="item.app_category">{{ item.app_category }}</option>
                  </select>
                </div>
                <div class="col-md-12 mb-3" v-else>
                  <label for="validationcategory">
                    {{ cvCategoryLabel }}</label><br/>
                  <input v-model="vmQuestionBankFormData.app_category" type="text" class="form-control" required disabled/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcategory">Select Type</label><br/>
                  <select v-model="vmQuestionBankFormData.is_mcq" class="form-control">
                    <option :value="1">MCQ</option>
                    <option :value="0">ESSAY TYPE</option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationquestion">
                    {{ cvQuestionLabel }}</label>
                    <vue-editor class="katex" :editorToolbar="customToolbarAdmin" v-model="vmQuestionBankFormData.question"></vue-editor>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="app_name">
                  {{ cvAppDifficultyLebel }}</label>
                  <ValidationProvider
                    name="Question Difficulty"
                    rules="required"
                    v-slot="{ errors }">
                    <select class="form-control" v-model="vmQuestionBankFormData.difficulty">
                      <option value="easy">Easy</option>
                      <option value="medium">Medium</option>
                      <option value="hard">Hard</option>
                    </select>
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="app_name">
                  {{ cvAppSkillLebel }}</label>
                  <ValidationProvider
                    name="Question skill cannot be empty"
                    rules="required"
                    v-slot="{ errors }">
                    <input v-model="vmQuestionBankFormData.skill" type="text" class="form-control mb-0" >
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div>

                <div class="mt-4 w-100">
                  <h4 class="mb-3">
                    {{ cvPBACoverPic }} : {{ imageSize }}
                  </h4>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                        <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                        <i v-else class="fas fa-image d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                          <input class="file-upload" type="file" required accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                        </label>
                        <span class="text-danger" ref="coverPicError"></span>
                      </div>
                    </div>
                  </div>
                  <!-- Add Answer options -->
                  <template v-if="vmQuestionBankFormData.is_mcq == 1">
                    <div class="form-group col-12 markRow">
                    <div> <label for="correctedOption">{{cvoptionAnswersLabel}}</label></div>
                      <span class="addIcon pointer" @click="addOption()">Add Option <i class="fa-solid fa-circle-plus"></i></span>
                    </div>
                    <div :key="update" class="row w-100">
                      <div class="form-group w-100">
                        <template v-for="(val, option) in vmQuestionBankFormData.answer_choices" >
                          <div class="col-12 answerOption mt-1" :key="option">
                            <span class="badge bg-primary text-white rounded-pill optionAddSpan">{{option}}</span>
                            <!-- <input v-model="vmQuestionBankFormData.answer_choices[option]" type="text" class="form-control mb-0" id="marks"> -->
                            <span class="addIcon answerOptionAddBtn" @click="removeOption(option)"><i class="fa-solid fa-circle-xmark"></i></span>
                            <vue-editor class="katex" :editorToolbar="customToolbarAdmin" v-model="vmQuestionBankFormData.answer_choices[option]"></vue-editor>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                  <!-- Add Answer options end -->

                  <div class="col-md-12 mb-3">
                    <label for="validationqbkcategory">
                      {{ cvQbkCategoryLabel }}</label><br/>
                    <select v-model="vmQuestionBankFormData.qbk_category_option" class="form-control">
                      <option disabled value="">Please select one</option>
                      <option v-for="item in vmqbkcategoryList" :key="item.qbk_category">{{ item.qbk_category }}</option>
                      <option value="AddNew">Add New</option>
                    </select>
                    <div v-if="vmQuestionBankFormData.qbk_category_option ==='AddNew' ">
                      <input v-model="vmQuestionBankFormData.qbk_category" placeholder="Enter the question bank category" type="text" class="form-control" required />
                    </div>
                  </div>

                  <!-- Correct Answer -->
                  <div class="col-md-12 mb-3">
                    <label for="validationcorrect_answers">
                      {{ cvCorrectAnswersLabel }}
                    </label>
                    <input v-model="vmQuestionBankFormData.correct_answers" type="text" class="form-control"/>
                  </div><!-- Correct Answer -->

                  <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3 gide_inline_textorcheckbox">
                    <label class="lable" for="app_has_calc">
                      {{ cvExamCalcReq }}
                    </label>
                    <ValidationProvider
                      name="Show Calculator for this Question ?"
                      rules="required"
                      v-slot="{ errors }">
                      <input id="" v-model="vmQuestionBankFormData.has_calc" type="checkbox" class="form-control" required />
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>

                  <!-- Answer explanation -->
                  <div class="col-md-12 mb-3">
                    <label for="validationcorrect_answers">
                      {{ cvExplanationLabel }}
                    </label>
                    <template>
                      <vue-editor class="katex" :editorToolbar="customToolbarAdmin" v-model="vmQuestionBankFormData.answer_explanation"></vue-editor>
                    </template>
                  </div><!-- Answer explanation -->
                </div>
              </div>
              <div class="row spinner_add">
                <div class="col-7">
                <button type="button" class="btn btn-primary" @click="quizBankAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
                <div class="col-5">
                  <button  v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning"></b-spinner>
                  </button>
                </div>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style scoped lang="scss">
  .fa-circle-plus{
    font-size: 16px;
    position: relative;
    top: 1px;
    float: right;
    color: #fb0000;
  }
  .answerOption {
    position: relative;
  }
  .answerOptionAddBtn {
    position: absolute;
    font-size: 20px;
    top: 7px;
    right: 5px;
  }
  .form-group {
    input {
      margin-left: 10px;
    }
  }
  .optionAddSpan {
    position: relative;
    height: 20px;
    top: 10px;
  }
</style>
<script>
import { QuizBanks } from "../../../FackApi/api/quizBank.js"
import { Apps } from "../../../FackApi/api/apps.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import CropImage from "../../../components/cropImage.vue"
import QuizType from "../../../FackApi/json/QuizType.js"
import { VueEditor } from "vue2-editor"
import userPermission from "../../../Utils/user_permission"
import { socialvue } from "../../../config/pluginInit.js"
import "katex/dist/katex.min.css"
import katex from "katex"
import { base64encode } from "nodejs-base64"

export default {
  name: "QuestionBankAdd",
  components: {
    CropImage,
    VueEditor
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propAppId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      apiName: "quiz_bank_add",
      cvCardTitle: "Add Question",
      cvSubmitBtn: "Add",
      cvCategoryLabel: "Quiz Category",
      cvQbkCategoryLabel: "Question Bank Category",
      cvQuestionLabel: "Question",
      cvAppDifficultyLebel: "Difficulty",
      cvAppSkillLebel: "Skill or Tag",
      cvAnswerChoicesLabel: "Answer Choices",
      cvCorrectAnswersLabel: "Correct Answer",
      cvExplanationLabel: "Answer Explanation",
      cvoptionAnswersLabel: "Options Answers",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Question Bank",
      vmQuestionBankFormData: Object.assign({}, this.initFormData()),
      cvPBACoverPic: "Does this Question have a Picture ? If yes then upload ",
      vmQuestionBankDesc: null,
      vmQuestionBankType: null,
      vmqbkExamCategoryList: " ",
      vmqbkExamCategoryData: " ",
      vmqbkcategoryList: " ",
      app_id: "",
      propCropDialogSize: "md",
      propCropImageDialogOpen: false,
      vmCoverPic: "",
      propStencilSize: null,
      fileSrc: "",
      vmPBACoverPicImage: Object.assign({}, this.initImageData()),
      isPhotoUploaded: false,
      imageMaxSize: " Maximum Size 4MB",
      quizDetail: {},
      QuizType: QuizType,
      imageSize: "Size  1080 * 566",
      cvExamCalcReq: "Show Calculator for this Question ?",
      update: 0,
      customToolbarAdmin: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video", "formula"],
        ["clean"] // remove formatting button
      ],
      cvImageSizeErrorMsg: " Image size must be less than 300 kb."
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    window.katex = katex

    if (this.$route.params.app_id) {
      this.app_id = this.$route.params.app_id
    }

    this.QuizType = QuizType(this.userData.user_role)
    this.appCategoryDistinct()
    this.qbkCategory()
    this.examView()
  },
  methods: {
    /**
     * addOption
     */
    addOption () {
      this.update += 1
      let index = Object.keys(this.vmQuestionBankFormData.answer_choices).length + 1
      let char = String.fromCharCode(96 + index)
      this.vmQuestionBankFormData.answer_choices[char] = ""
    },
    /**
     * removeOption
     * @Params optionNo that need to remove
     * first delete the option then all obj values in sorting order
     * like a,b,c,d and c remove then d will come at place of c
     */
    removeOption (optionNo) {
      let op = {}
      delete this.vmQuestionBankFormData.answer_choices[optionNo]
      const keys = Object.keys(this.vmQuestionBankFormData.answer_choices)

      for (let index = 0; index < keys.length; index++) {
        const char = String.fromCharCode(96 + index + 1)
        op[char] = this.vmQuestionBankFormData.answer_choices[keys[index]]
      }

      this.update += 1
      this.vmQuestionBankFormData.answer_choices = { ...op }
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        "is_mcq": 1,
        "difficulty": "",
        "skill": "",
        "app_category": "",
        "question": "",
        "correct_answers": "",
        "answer_choices": {}
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmQuestionBankFormData) {
          if (!this.vmQuestionBankFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * quizBankAdd
     */
    async quizBankAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true

        if (this.vmQuestionBankFormData.qbk_category_option !== "AddNew") {
          this.vmQuestionBankFormData.qbk_category = this.vmQuestionBankFormData.app_category
        }

        if (this.quizDetail.app_type === this.QuizType.COMPETITION || this.quizDetail.app_type === this.QuizType.COUNSELLING) {
          this.vmQuestionBankFormData.app_id = this.app_id
        }

        if (this.vmQuestionBankFormData.has_calc) {
          this.vmQuestionBankFormData.has_calc = 1
        }

        if (this.propAppId || this.$route.params.app_id) {
          this.vmQuestionBankFormData.app_id = this.propAppId ? this.propAppId : this.$route.params.app_id
        }

        if (this.vmQuestionBankFormData.answer_explanation) {
          this.vmQuestionBankFormData.answer_explanation = base64encode(this.vmQuestionBankFormData.answer_explanation)
        }

        let quizBankAddResp = await QuizBanks.quizBankAdd(this, this.vmQuestionBankFormData)
        ApiResponse.responseMessageDisplay(this, quizBankAddResp)
        if (quizBankAddResp && !quizBankAddResp.resp_status) {
          return false
        }

        if (this.isPhotoUploaded) {
          // Add image
          let imageAddResp = await this.imageAdd(quizBankAddResp.resp_data.qbk_id)
          if (imageAddResp && imageAddResp.resp_status) {
            quizBankAddResp.resp_data.image = imageAddResp.resp_data[0].image
          }
          this.isPhotoUploaded = false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseQuestionBankAddModal", quizBankAddResp.resp_data)
        }

        this.vmQuestionBankFormData = Object.assign({}, this.initFormData())

        if (this.$route.path != `/quizBank_list/${this.app_id}`) {
          this.$router.push(`/quizBank_list/${this.app_id}`)
        }
      }
      catch (err) {
        console.error("Exception occurred at quizBankAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * appCategoryDistinct
     */
    async appCategoryDistinct () {
      try {
        let qbkExamCategoryResp = await Apps.appCategoryDistinct(this)
        if (qbkExamCategoryResp && qbkExamCategoryResp.resp_status) {
          this.vmqbkExamCategoryList = qbkExamCategoryResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at appCategoryDistinct() and Exception:", err.message)
      }
    },
    /**
     * Question Exam View
     */
    async examView () {
      try {
        let examViewResp = await Apps.appView(this, this.app_id)
        if (examViewResp && examViewResp.resp_status) {
          this.quizDetail = examViewResp.resp_data.data
          this.vmQuestionBankFormData.app_category = this.quizDetail.app_category
        }
      }
      catch (err) {
        console.error("Exception occurred at getexamViewRespById() and Exception:", err.message)
      }
    },
    /**
     * appCategoryDistinct
     */
    async qbkCategory () {
      try {
        let qbkCategoryResp = await QuizBanks.qbkCategory(this)
        if (qbkCategoryResp && qbkCategoryResp.resp_status) {
          this.vmqbkcategoryList = qbkCategoryResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at qbkCategory() and Exception:", err.message)
      }
    },
    /**
     * openImageDialog
     */
    openImageDialog (e, imageType) {
      this.isPhotoUploaded = true
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 1080,
          "height": 566
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image = {
        "img": img,
        "name": orgImg.name
      }
      this.vmPBACoverPicImage.image_type = "cover_pic"
    },

    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * initPBAData
     */
    initPBAData () {
      return {
        question_name: "",
        module_id: ""
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (pbaId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "QUIZBANK",
          "module_id": pbaId,
          "image": []
        }

        if (this.vmPBACoverPicImage.image) {
          imageObj.image.push(this.vmPBACoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await QuizBanks.imageAddToQuizBank(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        this.vmPBACoverPicImage = Object.assign({}, this.initPBAData())
        imageObj = {}
        return imageUpdateResp
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
        return err
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    }
  }
}
</script>
